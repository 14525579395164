import React, { Component, Fragment, useState, useRef, useEffect, createRef } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import {
    TabContent, TabPane, Nav, NavItem, NavLink, Tooltip, UncontrolledTooltip, Table,
    Pagination, PaginationItem, PaginationLink
} from 'reactstrap';
import classnames from 'classnames';

import Header from "../../Common/Header";
import Footer from "../../Common/Footer";

import HtmlParser from "react-html-parser";
import { useSelector } from "react-redux";

const Terms = (props) => {

    React.useEffect(() => { window.scrollTo(0, 0); }, []);

    let data = useSelector(state => (state.user));
    console.log(data, "kpkpkdata");

    let data1, data2, data3, data4, data5, data6, data7, data8, data9, data10, data11, data12, data13, data14, data15, data16, data17,
        data18, data19, data20,data21;

    if (data.data.status == 200) {
        data = data.data.data;
        console.log(data, "data2");
        data1 = data.find(elem => elem.title == "Acceptance of Terms");
        data2 = data.find(elem => elem.title == "Disclaimer and Changes of Terms of Use");
        data3 = data.find(elem => elem.title == "Eligibility");
        data4 = data.find(elem => elem.title == "Access To Account and Security")
        data5 = data.find(elem => elem.title == "Representations and Risks")
        data6 = data.find(elem => elem.title == "Vulnerabilities or Exploits in Cryptography")
        data7 = data.find(elem => elem.title == "Taxes")
        data8 = data.find(elem => elem.title == "Transactions and Feeds")
        data9 = data.find(elem => elem.title == "Changes")
        data10 = data.find(elem => elem.title == "Disclaimers1")
        data11 = data.find(elem => elem.title == "Limitation on Liability")
        data12 = data.find(elem => elem.title == "Ownership Rights")
        data13 = data.find(elem => elem.title == "Ownership and management of LAND")
        data14 = data.find(elem => elem.title == "Open Source License.")
        data15 = data.find(elem => elem.title == "Trademarks")
        data16 = data.find(elem => elem.title == "Links")
        data17 = data.find(elem => elem.title == "Protocol for Reporting Copyright Infringement Claims")
        data18 = data.find(elem => elem.title == "Binding Arbitration and Waiver Of Class Action")
        data19 = data.find(elem => elem.title == "General Information")
        data20 = data.find(elem => elem.title == "Titles of Selection")
        data21 = data.find(elem => elem.title == "Definitions")




    }
    console.log(data2, "data5");

    return (
        <Fragment>

            <Header />

            <div class="maincontent">
                <div class="pageContent pt-0">
                    <section class="bannerPage">
                        <div class="bannerset"></div>
                        <div class="container">
                            <div class="row justify-content-center">
                                <div class="col-lg-10">
                                    <div class="pageTitle">
                                        <span>Terms & Conditions</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <div class="cmsbody">
                        <div class="container">
                            <div class="row">
                                <div class="col-12">
                                    <div class="cmstit">Terms and Conditions</div>
                                    <div class="cmscnt">
                                        <p>Welcome to Metapark.com!</p>
                                        <p>These terms and conditions outline the rules and regulations for the use of Metapark's Website, located at www.Metapark.com.</p>
                                        <p>By accessing this website we assume you accept these terms and conditions. Do not continue to use Metapark.com if you do not agree to take all of the terms and conditions stated on this page.</p>
                                        <p>By accessing this website we assume you accept these terms and conditions. Do not continue to use Metapark.com if you do not agree to take all of the terms and conditions stated on this page.</p>
                                    </div>
                                    <div class="cmstit">{data1 ? HtmlParser((data1.title).replace(/(<([^>]+)>)/gi, "")) : <></>}</div>
                                    <div class="cmscnt">
                                        <p>{data1 ? HtmlParser((data1.content_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                        <p>{data1 ? HtmlParser((data1.privacy_content).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                        <p>{data1 ? HtmlParser((data1.meta_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                        <p>{data1 ? HtmlParser((data1.privacy).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                        <p>{data1 ? HtmlParser((data1.terms).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                    </div>
                                    {/* <div class="cmstit">License</div>
                                    <div class="cmscnt">
                                        <p>Unless otherwise stated, Metapark and/or its licensors own the intellectual property rights for all material on Metapark.com. All intellectual property rights are reserved. You may access this from Metapark.com for your own personal use subjected to restrictions set in these terms and conditions.</p>
                                        <p>You must not:</p>

                                        <ul>
                                            <li>Republish material from Metapark.com</li>
                                            <li>Sell, rent or sub-license material from Metapark.com</li>
                                            <li>Reproduce, duplicate or copy material from Metapark.com</li>
                                            <li>Redistribute content from Metapark.com</li>
                                        </ul>

                                        <p>This Agreement shall begin on the date hereof. Our Terms and Conditions were created with the help of the Free Terms and Conditions Generator.</p>
                                        <p>Metapark reserves the right to monitor all Comments and to remove any Comments which can be considered inappropriate, offensive or causes breach of these Terms and Conditions.</p>
                                        <p>You warrant and represent that:</p>

                                        <ul>
                                            <li>You are entitled to post the Comments on our website and have all necessary licenses and consents to do so;</li>
                                            <li>The Comments do not invade any intellectual property right, including without limitation copyright, patent or trademark of any third party;</li>
                                            <li>The Comments do not contain any defamatory, libelous, offensive, indecent or otherwise unlawful material which is an invasion of privacy</li>
                                            <li>The Comments will not be used to solicit or promote business or custom or present commercial activities or unlawful activity</li>
                                        </ul>

                                        <p>You hereby grant Metapark a non-exclusive license to use, reproduce, edit and authorize others to use, reproduce and edit any of your Comments in any and all forms, formats or media.</p>

                                    </div> */}

                                    <div class="cmstit">{data2 ? HtmlParser((data2.title).replace(/(<([^>]+)>)/gi, "")) : <></>}</div>
                                    <div class="cmscnt">
                                        <p>{data2 ? HtmlParser((data2.content_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                        <p>{data2 ? HtmlParser((data2.privacy_content).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                    </div>

                                    <div class="cmstit">{data3 ? HtmlParser((data3.title).replace(/(<([^>]+)>)/gi, "")) : <></>}</div>
                                    <div class="cmscnt">
                                        <p>{data3 ? HtmlParser((data3.privacy_content).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                    </div>

                                    <div class="cmstit">{data4 ? HtmlParser((data4.title).replace(/(<([^>]+)>)/gi, "")) : <></>}</div>
                                    <div class="cmscnt">
                                        <p>{data4 ? HtmlParser((data4.content_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                        <p>{data4 ? HtmlParser((data4.privacy_content).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                        <p>{data4 ? HtmlParser((data4.meta_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                        <p>{data4 ? HtmlParser((data4.privacy).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                        <p>{data4 ? HtmlParser((data4.terms).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                        <p>{data4 ? HtmlParser((data4.terms_content).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>

                                    </div>
                                    <div class="cmstit">{data5 ? HtmlParser((data5.title).replace(/(<([^>]+)>)/gi, "")) : <></>}</div>
                                    <div class="cmstit">{data5 ? HtmlParser((data5.title1).replace(/(<([^>]+)>)/gi, "")) : <></>}</div>
                                    <div class="cmscnt">
                                        <p>{data5 ? HtmlParser((data5.content_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                    </div>

                                    <div class="cmstit">{data5 ? HtmlParser((data5.subtitle).replace(/(<([^>]+)>)/gi, "")) : <></>}</div>
                                    <div class="cmscnt">
                                        <p>{data5 ? HtmlParser((data5.privacy_content).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                    </div>

                                    <div class="cmstit">{data6 ? HtmlParser((data6.title).replace(/(<([^>]+)>)/gi, "")) : <></>}</div>
                                    <div class="cmscnt">
                                        <p>{data6 ? HtmlParser((data6.content_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                    </div>

                                    <div class="cmstit">{data6 ? HtmlParser((data6.title1).replace(/(<([^>]+)>)/gi, "")) : <></>}</div>
                                    <div class="cmscnt">
                                        <p>{data6 ? HtmlParser((data6.privacy_content).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                    </div>

                                    <div class="cmstit">{data6 ? HtmlParser((data6.subtitle).replace(/(<([^>]+)>)/gi, "")) : <></>}</div>
                                    <div class="cmscnt">
                                        <p>{data6 ? HtmlParser((data6.meta_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                    </div>

                                    <div class="cmstit">{data6 ? HtmlParser((data6.subtitle1).replace(/(<([^>]+)>)/gi, "")) : <></>}</div>
                                    <div class="cmscnt">
                                        <p>{data6 ? HtmlParser((data6.privacy).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                    </div>

                                    <div class="cmstit">{data7 ? HtmlParser((data7.title).replace(/(<([^>]+)>)/gi, "")) : <></>}</div>
                                    <div class="cmscnt">
                                        <p>{data7 ? HtmlParser((data7.content_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                    </div>

                                    <div class="cmstit">{data7 ? HtmlParser((data7.title1).replace(/(<([^>]+)>)/gi, "")) : <></>}</div>
                                    <div class="cmscnt">
                                        <p>{data7 ? HtmlParser((data7.privacy_content).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                    </div>

                                    <div class="cmstit">{data7 ? HtmlParser((data7.subtitle).replace(/(<([^>]+)>)/gi, "")) : <></>}</div>
                                    <div class="cmscnt">
                                        <p>{data7 ? HtmlParser((data7.meta_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                    </div>

                                    <div class="cmstit">{data7 ? HtmlParser((data7.subtitle1).replace(/(<([^>]+)>)/gi, "")) : <></>}</div>
                                    <div class="cmscnt">
                                        <p>{data7 ? HtmlParser((data7.privacy).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                    </div>

                                    <div class="cmstit">{data8 ? HtmlParser((data8.title).replace(/(<([^>]+)>)/gi, "")) : <></>}</div>
                                    <div class="cmscnt">
                                        <p>{data8 ? HtmlParser((data8.content_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                        <p>{data8 ? HtmlParser((data8.privacy_content).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                        <p>{data8 ? HtmlParser((data8.meta_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                        <p>{data8 ? HtmlParser((data8.privacy).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                    </div>

                                    <div class="cmstit">{data9 ? HtmlParser((data9.title).replace(/(<([^>]+)>)/gi, "")) : <></>}</div>
                                    <div class="cmscnt">
                                        <p>{data9 ? HtmlParser((data9.content_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                    </div>

                                    <div class="cmstit">{data9 ? HtmlParser((data9.title1).replace(/(<([^>]+)>)/gi, "")) : <></>}</div>
                                    <div class="cmscnt">
                                        <p>{data9 ? HtmlParser((data9.privacy_content).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                    </div>

                                    <div class="cmstit">{data9 ? HtmlParser((data9.subtitle).replace(/(<([^>]+)>)/gi, "")) : <></>}</div>
                                    <div class="cmscnt">
                                        <p>{data9 ? HtmlParser((data9.meta_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>

                                    </div>
                                    <div class="cmstit">{data9 ? HtmlParser((data9.subtitle1).replace(/(<([^>]+)>)/gi, "")) : <></>}</div>
                                    <div class="cmscnt">
                                        <p>{data9 ? HtmlParser((data9.privacy).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                        <p>{data9 ? HtmlParser((data9.terms).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                        <p>{data9 ? HtmlParser((data9.terms_content).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                        <p>{data10 ? HtmlParser((data10.content_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                        <p>{data10 ? HtmlParser((data10.privacy_content).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                        <p>{data10 ? HtmlParser((data10.meta_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                        <p>{data10 ? HtmlParser((data10.privacy).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                        <p>{data10 ? HtmlParser((data10.terms).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                        <p>{data10 ? HtmlParser((data10.terms_content).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>

                                    </div>

                                    <div class="cmstit">{data11 ? HtmlParser((data11.title).replace(/(<([^>]+)>)/gi, "")) : <></>}</div>
                                    <div class="cmscnt">
                                        <p>{data11 ? HtmlParser((data11.content_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                        <p>{data11 ? HtmlParser((data11.privacy_content).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                        <p>{data11 ? HtmlParser((data11.meta_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                        <p>{data11 ? HtmlParser((data11.privacy).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                    </div>

                                    <div class="cmstit">{data12 ? HtmlParser((data12.title).replace(/(<([^>]+)>)/gi, "")) : <></>}</div>
                                    <div class="cmscnt">
                                        <p>{data12 ? HtmlParser((data12.content_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                        <p>{data12 ? HtmlParser((data12.privacy_content).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                        <p>{data12 ? HtmlParser((data12.meta_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                    </div>

                                    <div class="cmstit">{data13 ? HtmlParser((data13.title1).replace(/(<([^>]+)>)/gi, "")) : <></>}</div>
                                    <div class="cmscnt">
                                        <p>{data13 ? HtmlParser((data13.content_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                        <p>{data13 ? HtmlParser((data13.privacy_content).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                        <p>{data13 ? HtmlParser((data13.meta_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                        <p>{data13 ? HtmlParser((data13.privacy).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                    </div>

                                    <div class="cmstit">{data14 ? HtmlParser((data14.title).replace(/(<([^>]+)>)/gi, "")) : <></>}</div>
                                    <div class="cmstit">{data14 ? HtmlParser((data14.title1).replace(/(<([^>]+)>)/gi, "")) : <></>}</div>
                                    <div class="cmscnt">
                                        <p>{data14 ? HtmlParser((data14.content_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                    </div>

                                    <div class="cmstit">{data14 ? HtmlParser((data14.subtitle).replace(/(<([^>]+)>)/gi, "")) : <></>}</div>
                                    <div class="cmscnt">
                                        <p>{data14 ? HtmlParser((data14.privacy_content).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                    </div>

                                    <div class="cmstit">{data14 ? HtmlParser((data14.subtitle1).replace(/(<([^>]+)>)/gi, "")) : <></>}</div>
                                    <div class="cmscnt">
                                        <p>{data14 ? HtmlParser((data14.meta_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                    </div>

                                    <div class="cmstit">{data14 ? HtmlParser((data14.terms).replace(/(<([^>]+)>)/gi, "")) : <></>}</div>
                                    <div class="cmscnt">
                                        <p>{data14 ? HtmlParser((data14.terms_content).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                    </div>

                                    <div class="cmstit">{data15 ? HtmlParser((data15.title).replace(/(<([^>]+)>)/gi, "")) : <></>}</div>
                                    <div class="cmscnt">
                                        <p>{data15 ? HtmlParser((data15.content_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                    </div>

                                    <div class="cmstit">{data15 ? HtmlParser((data15.title1).replace(/(<([^>]+)>)/gi, "")) : <></>}</div>
                                    <div class="cmscnt">
                                        <p>{data15 ? HtmlParser((data15.privacy_content).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                    </div>

                                    <div class="cmstit">{data15 ? HtmlParser((data15.subtitle).replace(/(<([^>]+)>)/gi, "")) : <></>}</div>
                                    <div class="cmscnt">
                                        <p>{data15 ? HtmlParser((data15.meta_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                    </div>

                                    <div class="cmstit">{data15 ? HtmlParser((data15.subtitle1).replace(/(<([^>]+)>)/gi, "")) : <></>}</div>
                                    <div class="cmscnt">
                                        <p>{data15 ? HtmlParser((data15.privacy).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                    </div>

                                    <div class="cmstit">{data16 ? HtmlParser((data16.title).replace(/(<([^>]+)>)/gi, "")) : <></>}</div>
                                    <div class="cmscnt">
                                        <p>{data16 ? HtmlParser((data16.content_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                    </div>
                                    <div class="cmstit">{data16 ? HtmlParser((data16.title1).replace(/(<([^>]+)>)/gi, "")) : <></>}</div>
                                    <div class="cmscnt">
                                        <p>{data16 ? HtmlParser((data16.privacy_content).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                        <p>{data16 ? HtmlParser((data16.meta_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                        <p>{data16 ? HtmlParser((data16.privacy).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                    </div>

                                    <div class="cmstit">{data16 ? HtmlParser((data16.subtitle).replace(/(<([^>]+)>)/gi, "")) : <></>}</div>
                                    <div class="cmscnt">
                                        <p>{data16 ? HtmlParser((data16.terms).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                    </div>

                                    <div class="cmstit">{data17 ? HtmlParser((data17.title).replace(/(<([^>]+)>)/gi, "")) : <></>}</div>
                                    <div class="cmscnt">
                                        <p>{data17 ? HtmlParser((data17.content_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                        <ul>
                                            <li>
                                                {data17 ? HtmlParser((data17.privacy_content).replace(/(<([^>]+)>)/gi, "")) : <></>}
                                            </li>
                                        </ul>
                                        <p>{data17 ? HtmlParser((data17.subtitle).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                        <p>{data17 ? HtmlParser((data17.meta_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                        <p>{data17 ? HtmlParser((data17.privacy).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>

                                    </div>

                                    <div class="cmstit">{data18 ? HtmlParser((data18.title).replace(/(<([^>]+)>)/gi, "")) : <></>}</div>
                                    <div class="cmscnt">
                                        <p>{data18 ? HtmlParser((data18.content_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                    </div>

                                    <div class="cmstit">{data18 ? HtmlParser((data18.title1).replace(/(<([^>]+)>)/gi, "")) : <></>}</div>
                                    <div class="cmscnt">
                                        <p>{data18 ? HtmlParser((data18.privacy_content).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                    </div>

                                    <div class="cmstit">{data18 ? HtmlParser((data18.subtitle).replace(/(<([^>]+)>)/gi, "")) : <></>}</div>
                                    <div class="cmscnt">
                                        <p>{data18 ? HtmlParser((data18.meta_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                        <p>{data18 ? HtmlParser((data18.privacy).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                    </div>

                                    <div class="cmstit">{data18 ? HtmlParser((data18.subtitle1).replace(/(<([^>]+)>)/gi, "")) : <></>}</div>
                                    <div class="cmscnt">
                                        <p>{data18 ? HtmlParser((data18.terms).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                    </div>

                                    <div class="cmstit">{data19 ? HtmlParser((data19.title).replace(/(<([^>]+)>)/gi, "")) : <></>}</div>

                                    <div class="cmstit">{data19 ? HtmlParser((data19.title1).replace(/(<([^>]+)>)/gi, "")) : <></>}</div>
                                    <div class="cmscnt">
                                        <p>{data19 ? HtmlParser((data19.content_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                    </div>

                                    <div class="cmstit">{data19 ? HtmlParser((data19.subtitle).replace(/(<([^>]+)>)/gi, "")) : <></>}</div>
                                    <div class="cmscnt">
                                        <p>{data19 ? HtmlParser((data19.privacy_content).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                    </div>

                                    <div class="cmstit">{data19 ? HtmlParser((data19.subtitle1).replace(/(<([^>]+)>)/gi, "")) : <></>}</div>
                                    <div class="cmscnt">
                                        <p>{data19 ? HtmlParser((data19.meta_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                    </div>

                                    <div class="cmstit">{data20 ? HtmlParser((data20.title).replace(/(<([^>]+)>)/gi, "")) : <></>}</div>
                                    <div class="cmscnt">
                                        <p>{data20 ? HtmlParser((data20.content_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                    </div>

                                    <div class="cmstit">{data20 ? HtmlParser((data20.title1).replace(/(<([^>]+)>)/gi, "")) : <></>}</div>
                                    <div class="cmscnt">
                                        <p>{data20 ? HtmlParser((data20.privacy_content).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                    </div>

                                    <div class="cmstit">{data20 ? HtmlParser((data20.title).replace(/(<([^>]+)>)/gi, "")) : <></>}</div>
                                    <div class="cmstit">{data20 ? HtmlParser((data20.title1).replace(/(<([^>]+)>)/gi, "")) : <></>}</div>                                
                                    <div class="cmscnt">
                                        <p>{data20 ? HtmlParser((data20.content_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                        <p>{data20 ? HtmlParser((data20.privacy_content).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                        <p>{data20 ? HtmlParser((data20.meta_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                        <p>{data20 ? HtmlParser((data20.privacy).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                        <p>{data20 ? HtmlParser((data20.terms).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                        <p>{data20 ? HtmlParser((data20.terms_content).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />

        </Fragment >
    );

}

export default Terms;