import React, { Component, Fragment, useState, useRef, useEffect, createRef } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import {
    TabContent, TabPane, Nav, NavItem, NavLink, Tooltip, UncontrolledTooltip, Table,
    Pagination, PaginationItem, PaginationLink
} from 'reactstrap';
import classnames from 'classnames';

import metaparkBlack from "../../assets/images/metapark-black.svg"
import undermaintenance from "../../assets/images/undermaintenance.svg"

const Maintenance = (props) => {

    return (
        <Fragment>

            <div class="notfoundpage vh-100 py-4">
                <div class="container h-100">
                    <div class="row align-items-center h-100">
                        <div class="col-lg-8 mb-4 mb-lg-0">
                            <div class="mb-4">
                                <img src={metaparkBlack} alt="metapark-black" class="img-fluid" />
                            </div>
                            <div class="pagetit mb-0">Website Under Maintenance</div>
                            <div class="fs-15 fw-400 mb-4">Thanks for your patience...!</div>
                            <div>
                                <button class="btn btn-black" type="button">Learn More</button>
                            </div>

                        </div>
                        <div class="col-lg-4 text-center">
                            <img src={undermaintenance} alt="undermaintenance" class="img-fluid" />
                        </div>
                    </div>
                </div>
            </div>

        </Fragment >
    );

}

export default Maintenance;