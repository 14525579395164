import React, { Component, Fragment, useState, useRef, useEffect, createRef } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import {
    TabContent, TabPane, Nav, NavItem, NavLink, Tooltip, UncontrolledTooltip, Table,
    Pagination, PaginationItem, PaginationLink
} from 'reactstrap';
import classnames from 'classnames';

import metaparkBlack from "../../assets/images/metapark-black.svg"
import Page404 from "../../assets/images/404Page.svg"

const Notfound = (props) => {

    return (
        <Fragment>

            <div class="notfoundpage vh-100 py-4">
                <div class="container h-100">
                    <div class="row align-items-center h-100">
                        <div class="col-lg-6 mb-4 mb-lg-0">
                            <div class="mb-4">
                                <img src={metaparkBlack} alt="metapark-black"  class="img-fluid"/>
                            </div>
                            <div class="pagetit mb-3">Oops... Page not found</div>
                            <div class="fs-15 fw-400 mb-3">We will back soon...</div>
                            <div>
                                <a class="btn btn-black" href="/"  type="button">Back to Home</a>
                            </div>

                        </div>
                        <div class="col-lg-6 text-center">
                            <img src={Page404} alt="404Page" class="img-fluid" />
                        </div>
                    </div>
                </div>
            </div>

        </Fragment >
    );

}

export default Notfound;