import React, { Component, Fragment, useState, useRef, useEffect, createRef } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import {
    TabContent, TabPane, Nav, NavItem, NavLink, Tooltip, UncontrolledTooltip, Table,
    Pagination, PaginationItem, PaginationLink
} from 'reactstrap';
import classnames from 'classnames';

import footerlogo from "../assets/images/footerlogo.svg";

import { HashLink } from 'react-router-hash-link';

import { CONST } from '../_config/index';
import axios from "axios";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { NewsletterpostData } from '../redux/action'

const Home = (props) => {

    // toast.configure()

    const [activeTab, setActiveTab] = useState('1');
    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }
    const [Email, setEmail] = useState("")
    let dispatch = useDispatch();


    let data = useSelector(state => (state.user));
    console.log(data)
    // if (data.data3.status == true) {
    //     toast.success(data.data3.message, {
    //         toastId: 'EmailSuccess',
    //     });

    // }
    // else {
    //     toast.error(data.data3.message, {
    //         toastId: 'EmailFail',
    //     });
    // }


    var Pattern = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;

    // const EmailSend = async () => {
    //     console.log(Pattern, Email, Pattern == Email)
    //     if (Email == "" || Email == null || Email == undefined) {

    //         toast.info("kindly Enter Email", {
    //             toastId: 'EmailInfo',
    //         });
    //     }

    //     else if (Pattern.test(Email)) {


    //         let data = {
    //             email: Email
    //         }
    //         let data2 = await dispatch(NewsletterpostData(data));

    //         console.log(data2)

    //         if (data2.payload.data?.status == true) {
    //             toast.success(data2.payload.message, {
    //                 toastId: 'EmailSuccess',
    //             });

    //         }
    //         else {
    //             toast.error(data2.payload.message, {
    //                 toastId: 'EmailFail',
    //             });
    //         }
    //     }
    //     else {
    //         toast.error("Enter Valid Email", {
    //             toastId: 'EmailFail',
    //         });
    //         // alert('hiii')
    //     }
    // }

    // const onChangeHandler = (event) => {
    //     console.log(event.target.value, "eventt");
    //     const value = event.target.value;
    //     setEmail(value);
    // };



    const EmailSend = async () => {
        console.log(Pattern, Email, Pattern == Email)
        if (Email == "" || Email == null || Email == undefined) {

            toast.info("kindly Enter Email", {
                toastId: 'EmailInfo',
            });
            // createrrorElement('error', 'kindly Enter Email');

        }

        else if (Pattern.test(Email)) {


            let data = {
                email: Email
            }
            let data2 = await dispatch(NewsletterpostData(data));

            console.log(data2)

            if (data2.payload.data?.status == true) {
                toast.success(data2.payload.message, {
                    toastId: 'EmailSuccess',
                });
                setEmail('')

            }
            else {
                toast.error(data2.payload.message, {
                    toastId: 'EmailFail',
                });
                // createrrorElement('error', data2.payload.message)
            }
        }
        else {
            toast.error("Enter Valid Email", {
                toastId: 'EmailFail',
            });
            // alert('hiii')
        }
    }

    const onChangeHandler = (event) => {
        console.log(event.target.value, "eventt");
        const value = event.target.value;
        setEmail(value);
    };


    return (
        <Fragment>
            <ToastContainer theme="colored" />
            <footer class="footer1">
                <div class="container">
                    <div class="row align-items-center justify-content-center">
                        <div class="col-lg-10">
                            <div class="Subscribebox">
                                <div class="row align-items-center">
                                    <div class="col-lg-5 mb-3 mb-lg-0">
                                        <div class="jourEcoTit">Join <span class="fw-500">Our Ecosystem</span></div>
                                        <div class="jnourecoTxt">
                                            Subscribe to our newsletter for latest
                                            updates form <span class="fw-500">Metapark</span>
                                        </div>
                                    </div>
                                    <div class="col-lg-7">
                                        <div class="form-group formInputs row form-row mb-0">
                                            <div class="col-md-9">
                                                <input type="text"
                                                    className="form-control footerinput"
                                                    placeholder="Enter Email"
                                                    onChange={(event) => onChangeHandler(event)}
                                                    value={Email || ""}
                                                    required                                               
                                                />
                                            </div>
                                            <div class="col-md-3">
                                                <button class="btn btn-black v2 btn-block mw-auto mt-3 mt-md-0" type="button" onClick={EmailSend}>Subscribe</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="footerSec">
                    <div class="container">

                        <div class="row">
                            <div class="col-lg-4">
                                <div class="footerTitle mb-4">
                                    <img src={footerlogo} class="img-fluid logolight" />
                                </div>
                                <div class="footerTxt mb-4">
                                    Metapark aims to <span class="fw-500">establish a well formed network that allows its content creators to own and capture the full value of their contributions</span> in Web3.0 and AI.
                                </div>
                                <div class="fs-16 fw-600 mb-3">Follow Us</div>
                                <div class="mb-4 mb-lg-0">
                                    <div class="Footersocials">
                                        <div>
                                            <a href="" class="">
                                                <svg width="11" height="17" viewBox="0 0 11 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M0.833252 6.83333V10.1667H3.33325V16H6.66658V10.1667H9.16658L9.99992 6.83333H6.66658V5.16667C6.66658 4.94565 6.75438 4.73369 6.91066 4.57741C7.06694 4.42113 7.2789 4.33333 7.49992 4.33333H9.99992V1H7.49992C6.39485 1 5.33504 1.43899 4.55364 2.22039C3.77224 3.00179 3.33325 4.0616 3.33325 5.16667V6.83333H0.833252Z" stroke="white" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                            </a>
                                        </div>
                                        <div>
                                            <a href="" class="">
                                                <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M17.3334 1.34119C16.5001 1.74952 15.6834 1.91536 14.8334 2.16619C13.8992 1.11202 12.5142 1.05369 11.1834 1.55202C9.85258 2.05036 8.98091 3.26869 9.00008 4.66619V5.49952C6.29591 5.56869 3.88758 4.33702 2.33341 2.16619C2.33341 2.16619 -1.15159 8.36036 5.66675 11.3329C4.10675 12.372 2.55091 13.0729 0.666748 12.9995C3.42341 14.502 6.42758 15.0187 9.02841 14.2637C12.0117 13.397 14.4634 11.1612 15.4042 7.81202C15.6849 6.79341 15.8243 5.74107 15.8184 4.68452C15.8167 4.47702 17.0767 2.37452 17.3334 1.34036V1.34119Z" fill="none" stroke="white" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                            </a>
                                        </div>
                                        <div>
                                            <a href="" class="">
                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M12.9999 1.33301H2.99992C2.07944 1.33301 1.33325 2.0792 1.33325 2.99967V12.9997C1.33325 13.9201 2.07944 14.6663 2.99992 14.6663H12.9999C13.9204 14.6663 14.6666 13.9201 14.6666 12.9997V2.99967C14.6666 2.0792 13.9204 1.33301 12.9999 1.33301Z" stroke="white" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M4.66675 7.16699V11.3337" stroke="white" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M4.66675 4.66699V4.67533" stroke="white" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M8 11.3337V7.16699" stroke="white" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M11.3333 11.3337V8.83366C11.3333 8.39163 11.1577 7.96771 10.8452 7.65515C10.5326 7.34259 10.1087 7.16699 9.66667 7.16699C9.22464 7.16699 8.80072 7.34259 8.48816 7.65515C8.1756 7.96771 8 8.39163 8 8.83366" stroke="white" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                            </a>
                                        </div>
                                        <div>
                                            <a href="" class="">
                                                <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M13.1667 1.16699H4.83333C2.99238 1.16699 1.5 2.65938 1.5 4.50033V9.50033C1.5 11.3413 2.99238 12.8337 4.83333 12.8337H13.1667C15.0076 12.8337 16.5 11.3413 16.5 9.50033V4.50033C16.5 2.65938 15.0076 1.16699 13.1667 1.16699Z" stroke="white" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M7.33325 4.5L11.4999 7L7.33325 9.5V4.5Z" stroke="white" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                            </a>
                                        </div>
                                        <div>
                                            <a href="" class="">
                                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M5.66663 15.667L8.99996 8.16699" stroke="white" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M7.9167 10.6663C8.28087 11.7188 9.10837 12.333 10.0417 12.333C11.7675 12.333 13.1667 11.038 13.1667 8.99968C13.1674 8.3912 13.0349 7.78995 12.7783 7.23818C12.5218 6.68642 12.1475 6.19754 11.6818 5.8059C11.2162 5.41426 10.6703 5.12937 10.0828 4.97126C9.49519 4.81314 8.88011 4.78564 8.28076 4.89068C7.68142 4.99572 7.11235 5.23075 6.61357 5.57926C6.11478 5.92777 5.69837 6.3813 5.39362 6.90796C5.08887 7.43463 4.90317 8.02165 4.84957 8.62776C4.79597 9.23387 4.87578 9.84437 5.08337 10.4163" stroke="white" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5Z" stroke="white" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>

                                            </a>
                                        </div>
                                        <br />
                                        <div>
                                            <a href="" class="">
                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M11.3335 1.33301H4.66683C2.82588 1.33301 1.3335 2.82539 1.3335 4.66634V11.333C1.3335 13.174 2.82588 14.6663 4.66683 14.6663H11.3335C13.1744 14.6663 14.6668 13.174 14.6668 11.333V4.66634C14.6668 2.82539 13.1744 1.33301 11.3335 1.33301Z" stroke="white" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M8 10.5C9.38071 10.5 10.5 9.38071 10.5 8C10.5 6.61929 9.38071 5.5 8 5.5C6.61929 5.5 5.5 6.61929 5.5 8C5.5 9.38071 6.61929 10.5 8 10.5Z" stroke="white" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                            </a>
                                        </div>


                                        <div>
                                            <a href="" class="">
                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M13 1.33301H3.00004C2.07957 1.33301 1.33337 2.0792 1.33337 2.99967V12.9997C1.33337 13.9201 2.07957 14.6663 3.00004 14.6663H13C13.9205 14.6663 14.6667 13.9201 14.6667 12.9997V2.99967C14.6667 2.0792 13.9205 1.33301 13 1.33301Z" stroke="white" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M4.66663 5.5H5.49996L7.99996 8L10.5 5.5H11.3333" stroke="white" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M4.66663 10.5H6.33329" stroke="white" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M9.66663 10.5H11.3333" stroke="white" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M5.5 5.5V10.5" stroke="white" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M10.5 5.5V10.5" stroke="white" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                            </a>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="col-lg-8">
                                <div class="row justify-content-center">
                                    <div class="col-sm-6 col-md-4 col-lg-3 offset-lg-1">
                                        <div class="footerTitle">Quick Links</div>
                                        <div>
                                            <ul class="footerList">
                                                <li>
                                                    <HashLink to="/#homesec1">
                                                        Home
                                                    </HashLink>
                                                </li>
                                                <li>
                                                    <HashLink to="/#whyMetapark">
                                                        Why Metapark?
                                                    </HashLink>
                                                </li>
                                                <li>
                                                    <HashLink to="/#ecosystem">
                                                        Eco System
                                                    </HashLink>
                                                </li>
                                                <li>
                                                    <HashLink to="/#team">
                                                        Team
                                                    </HashLink>
                                                </li>
                                                <li>
                                                    <HashLink to="/#blogsec">
                                                        Community
                                                    </HashLink>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div class="col-sm-6 col-md-4 col-lg-3 offset-lg-1">
                                        <div class="footerTitle">Policy</div>
                                        <div>
                                            <ul class="footerList">
                                                <li>
                                                    <Link to="/privacy">
                                                        Privacy Policy
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/">AML Policy</Link>
                                                </li>
                                                <li>
                                                    <Link to="/">Risk Warning</Link>
                                                </li>
                                                <li>
                                                    <Link to="/terms">
                                                        Terms of Use
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/">Refund Policy</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div class="col-sm-12 col-md-4 col-lg-3 offset-lg-1">
                                        <div class="footerTitle">Support</div>
                                        <div>
                                            <ul class="footerList">
                                                <li>
                                                    <Link to="/">24/7 Chat Support</Link>
                                                </li>
                                                <li>
                                                    <Link to="/">How it Works</Link>
                                                </li>
                                                <li>
                                                    <Link to="/">FAQ</Link>
                                                </li>
                                                <li>
                                                    <Link to="/">Corporate Account</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                </div>
                            </div>



                        </div>
                    </div>
                </div>

                <div class="Footer-bottom">
                    <div class="container">
                        <div class="row">
                            <div class="col-12 secondfooter pt-3 pb-4">
                                <div class="text-center mx-auto">
                                    &copy; 2023 All rights reserved, By Metapark
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </footer >
        </Fragment >
    );

}

export default Home;