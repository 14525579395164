import React, { Component, Fragment, useState, useRef, useEffect, createRef } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch, Route, Link,useLocation } from "react-router-dom";
import {
    TabContent, TabPane, Nav, NavItem, NavLink, Tooltip, UncontrolledTooltip, Table,
    Pagination, PaginationItem, PaginationLink,Collapse
} from 'reactstrap';
import classnames from 'classnames';
import { HashLink } from 'react-router-hash-link';

import logo from '../assets/images/logo.svg'


// import Header from "../../common/Header/Header";
// import Footer from "../../common/Footer/Footer";

const Home = (props) => {
    
    const [collapsed, setCollapsed] = useState(true);
    const toggleNavbar = () => setCollapsed(!collapsed);

    const location = useLocation();

    return (
        <Fragment>
            <header>
                <nav class="navbar navbar-expand-xl navbar-light fixed-top mynavbar px-xl-0">
                    <div class="container">
                        <a class="navbar-brand" href="/" >
                            <img src={logo} class="logo" />
                        </a>
                        <button className={collapsed ? 'navbar-toggler collapsed' : 'navbar-toggler'}type="button" onClick={toggleNavbar}>
                            <span class="icon-bar top-bar"></span>
                            <span class="icon-bar middle-bar"></span>
                            <span class="icon-bar bottom-bar"></span>
                        </button>

                        <Collapse isOpen={!collapsed} navbar>
                            <ul class="navbar-nav nav mx-auto">
                                <li class="nav-item">                                    
                                    <HashLink to="/#homesec1" className={location.hash === '#homesec1' ? "nav-link active" : 'nav-link'}>
                                        <span>Home</span>
                                    </HashLink>
                                </li>
                                <li class="nav-item">
                                    <HashLink to="/#whyMetapark" className={location.hash === '#whyMetapark' ? "nav-link active" : 'nav-link'}>
                                        <span>Why Metapark?</span>
                                    </HashLink>
                                </li>
                                <li class="nav-item">
                                    <HashLink to="/#ecosystem" className={location.hash === '#ecosystem' ? "nav-link active" : 'nav-link'}>
                                        <span>Eco System</span>
                                    </HashLink>
                                </li>
                                <li class="nav-item">
                                    <HashLink to="/#team" className={location.hash === '#team' ? "nav-link active" : 'nav-link'}>
                                        <span>Team</span>
                                    </HashLink>
                                </li>
                                <li class="nav-item">
                                    <HashLink to="/#blogsec" className={location.hash === '#blogsec' ? "nav-link active" : 'nav-link'}>
                                        <span>Community</span>
                                    </HashLink>
                                </li>
                            </ul>
                            <div class="navbar-text">
                                <a href="/" class="btn btn-white ml-2 btn-12342" role="button">
                                    <span class="px-0">Explore</span>
                                </a>
                            </div>
                        </Collapse>
                    </div>
                </nav>
            </header>
        </Fragment>
    );

}

export default Home;