import React, { Component, Fragment, useState, useRef, useEffect, createRef } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import {
    TabContent, TabPane, Nav, NavItem, NavLink, Tooltip, UncontrolledTooltip, Table,
    Pagination, PaginationItem, PaginationLink
} from 'reactstrap';
import classnames from 'classnames';

import Header from "../../Common/Header";
import Footer from "../../Common/Footer";

import HtmlParser from "react-html-parser";
import { useSelector } from "react-redux";



const Privacy = (props) => {

    React.useEffect(() => { window.scrollTo(0, 0); }, []);


    let data = useSelector(state => (state.user));
   console.log(data, "kpkpkdata");

   let data1, data2, data3, data4, data5, data6, data7, data8, data9, data10;

   if (data.data.status == 200) {
       data = data.data.data;
        console.log(data,"data2");
       data1 = data.find(elem => elem.title == "Introduction");
       data2 = data.find(elem => elem.title == "Information Gathered and Stored on the Site and the Tools");
       data3 = data.find(elem => elem.title == "The Usage of Your Personal Information"); 
       data4 = data.find(elem => elem.title == "How is Your Information Is Utilized?")
       data5 = data.find(elem => elem.title == "Your Choice")
       data6 = data.find(elem => elem.title == "Privacy Rights")
       data7 = data.find(elem => elem.title == "Changes and Updates")

       
   }
   console.log(data5,"data5");

    return (
        <Fragment>

            <Header />

            <div class="maincontent">
                <div class="pageContent pt-0">
                    <section class="bannerPage">
                        <div class="bannerset"></div>
                        <div class="container">
                            <div class="row justify-content-center">
                                <div class="col-lg-10">
                                    <div class="pageTitle">
                                        <span>Privacy Policy</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <div class="cmsbody">
                        <div class="container">
                            <div class="row">
                                <div class="col-12">
                                    <div class="cmstit">{data1 ? HtmlParser((data1.title).replace(/(<([^>]+)>)/gi, "")) : <></>}</div>
                                    <div class="cmscnt">
                                        <p>{data1 ? HtmlParser((data1.content_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                        <p>{data1 ? HtmlParser((data1.privacy_content).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                        <p>{data1 ? HtmlParser((data1.meta_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                        <p>{data1 ? HtmlParser((data1.privacy).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                        <p>{data1 ? HtmlParser((data1.terms).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                        <p>{data1 ? HtmlParser((data1.terms_content).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>

                                    </div>
                                    <div class="cmstit">{data2 ? HtmlParser((data2.title).replace(/(<([^>]+)>)/gi, "")) : <></>}</div>
                                    <div class="cmscnt">
                                        <p>{data2 ? HtmlParser((data2.content_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                        <p>{data2 ? HtmlParser((data2.meta_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                        <p>{data2 ? HtmlParser((data2.privacy_content).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                        <p>{data2 ? HtmlParser((data2.privacy).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                        <p>{data2 ? HtmlParser((data2.terms).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>

                                    </div>
                                    <div class="cmstit">{data3 ? HtmlParser((data3.title).replace(/(<([^>]+)>)/gi, "")) : <></>}</div>
                                    <div class="cmscnt">
                                    <p>{data3 ? HtmlParser((data3.content_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                        <p>{data3 ? HtmlParser((data3.meta_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                        <p>{data3 ? HtmlParser((data3.privacy_content).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                    </div>

                                    <div class="cmstit">{data4 ? HtmlParser((data4.title).replace(/(<([^>]+)>)/gi, "")) : <></>}</div>
                                    <div class="cmscnt">
                                    <p>{data4 ? HtmlParser((data4.content_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                        <p>{data4 ? HtmlParser((data4.privacy_content).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                        <p>{data4 ? HtmlParser((data4.meta_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                        <p>{data4 ? HtmlParser((data4.privacy).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                        <p>{data4 ? HtmlParser((data4.terms).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                        <p>{data4 ? HtmlParser((data4.terms_content).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                    </div>
                                    {/* <div class="cmstit">We use the information we collect in various ways, including to:</div>
                                    <div class="cmscnt">
                                        <ul>
                                            <li>Provide, operate, and maintain our website</li>
                                            <li>Improve, personalize, and expand our website</li>
                                            <li>Understand and analyze how you use our website</li>
                                            <li>Develop new products, services, features, and functionality</li>
                                            <li>Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the website, and for marketing and promotional purposes</li>
                                            <li>Send you emails</li>
                                            <li>Find and prevent fraud</li>
                                        </ul>
                                    </div> */}

                                    <div class="cmstit">{data5 ? HtmlParser((data5.title).replace(/(<([^>]+)>)/gi, "")) : <></>}</div>
                                    <div class="cmscnt">
                                        <p>{data5 ? HtmlParser((data5.content_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                    </div>
                                    <div class="cmstit">{data5 ? HtmlParser((data5.title1).replace(/(<([^>]+)>)/gi, "")) : <></>}</div>
                                    <div class="cmscnt">
                                        <p>{data5 ? HtmlParser((data5.privacy_content).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                    </div>
                                    <div class="cmstit">{data5 ? HtmlParser((data5.subtitle).replace(/(<([^>]+)>)/gi, "")) : <></>}</div>
                                    <div class="cmscnt">
                                        <p>{data5 ? HtmlParser((data5.meta_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                    </div>
                                    <div class="cmstit">{data5 ? HtmlParser((data5.subtitle1).replace(/(<([^>]+)>)/gi, "")) : <></>}</div>
                                    <div class="cmscnt">
                                        <p>{data5 ? HtmlParser((data5.privacy).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                    </div>
                                    <div class="cmstit">{data6 ? HtmlParser((data6.title).replace(/(<([^>]+)>)/gi, "")) : <></>}</div>
                                    <div class="cmscnt">
                                        <p>{data6 ? HtmlParser((data6.content_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                        <p>{data6 ? HtmlParser((data6.privacy_content).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                        <p>{data6 ? HtmlParser((data6.meta_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                    </div>
                                    <div class="cmstit">{data7 ? HtmlParser((data7.title).replace(/(<([^>]+)>)/gi, "")) : <></>}</div>
                                    <div class="cmscnt">
                                        <p>{data7 ? HtmlParser((data7.content_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                        <p>{data7 ? HtmlParser((data7.privacy_content).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />

        </Fragment >
    );

}

export default Privacy;