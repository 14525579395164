import logo from './logo.svg';
import {Routes, Route} from 'react-router-dom';
import {Suspense} from "react";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import 'aos/dist/aos.css';

import './assets/scss/styles.scss';
import './assets/scss/custom.scss';
import Home from './Views/Home/Home';
import Maintenance from './Views/Maintenance/Maintenance';
import Privacy from './Views/Privacy/Privacy';
import Notfound from './Views/Notfound/Notfound';
import Terms from './Views/Terms/Terms';
import { useSelector } from "react-redux";
import { useDispatch } from 'react-redux';
import { useEffect } from "react";
import { getUserList, siteInform,NewsletterpostData } from "./redux/action";




function App() {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserList());
    dispatch(siteInform());
    
  }, []);

  const todos = useSelector((state) => state.user);
  console.log(todos);

  return (
    
    <Suspense fallback={<div className="loading" />}>
        <Routes>
          <Route path='/' element={<Home/>} />
          <Route path='/maintenance' element={<Maintenance/>} />
          <Route path='/privacy' element={<Privacy/>} />
          <Route path='/notfound' element={<Notfound/>} />
          <Route path='/terms' element={<Terms/>} />
        </Routes>
      </Suspense>

  );
}

export default App;
