import { createSlice } from "@reduxjs/toolkit";
import { getUserList, siteInform,eventsInfo,NewsletterpostData } from "./action";
 
const initialState = {
  data: [],
  data1:[],
  data2:[],
  data3:[],
  isLoading: false,
  isSuccess: false,
  value:0,
  loader:false,
  errorMessage: ''
}
 
export const userSlice = createSlice({
  name: 'user',
  initialState,
  extraReducers: {
    [getUserList.pending]: (state) => {
      state.loader = true;

    },
    [getUserList.fulfilled]: (state, { payload }) => {
      state.loader = false;
      state.isSuccess = true;
      state.data = payload;
    },
    [getUserList.rejected]: (state, { payload }) => {
      state.loader = false;
      state.isSuccess = false;
      state.errorMessage = payload
    },
    [siteInform.fulfilled] : (state,{payload}) => {
      state.data1 = payload;

    },
    [eventsInfo.fulfilled] : (state,{payload}) => {
      state.data2 = payload;

    },
    [NewsletterpostData.fulfilled] : (state,{payload}) => {
      state.data3 = payload;
    },

  //   loader:(state, action) => {
  //     state.loader = action.payload;
  // }

//   loaders: [
//     { test: /\.js$/, loader: 'babel', query: {compact: false} }
// ]

  }
})

export const {loader} = userSlice.actions;
 
export default userSlice.reducer;