import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { CONST } from "../_config";
 
export const getUserList = createAsyncThunk('user/getUserList', async ( rejectWithValue ) => {
  try {
    let { data } = await axios.get(CONST.BACKEND_URL+'users/getallcms');
    return data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
})

export const siteInform = createAsyncThunk('user/siteinform', async()=>{
  try{
    let { data } = await axios.get(CONST.BACKEND_URL + 'users/site_inform');
    return data; 
  }
  catch(err){

  }
});

export const eventsInfo = createAsyncThunk('user/geteventCollection', async()=>{
  try{
    let { data } = await axios.get(CONST.BACKEND_URL + 'geteventCollection');
    return data; 
  }
  catch(err){

  }
});


export const NewsletterpostData = createAsyncThunk(
  "type/postData",
  async (data) => {
    try {
      const response = await axios.post(CONST.BACKEND_URL + 'users/subscribe', data);
      // If you want to get something back
      return response.data;
    } catch (err) {
      console.error(err)
    }
  }
);
