import React, { Component, Fragment, useState, useRef, useEffect, createRef } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import {
    TabContent, TabPane, Nav, NavItem, NavLink, Tooltip, UncontrolledTooltip, Table,
    Pagination, PaginationItem, PaginationLink
} from 'reactstrap';
import classnames from 'classnames';

import Slider from "react-slick";
import AOS from 'aos';



import Header from "../../Common/Header";
import Footer from "../../Common/Footer";

import bannerSlide1 from "../../assets/images/bannerSlide1.svg"
import bannerSlide2 from "../../assets/images/bannerSlide2.svg"
import bannerSlide3 from "../../assets/images/bannerSlide3.svg"
import bannerSlide4 from "../../assets/images/bannerSlide4.svg"
import bannerSlide5 from "../../assets/images/bannerSlide5.svg"
import bannerSlide6 from "../../assets/images/bannerSlide6.svg"
import what_is_metapark from "../../assets/images/what_is_metapark.svg"
import whymetapark from "../../assets/images/whymetapark.svg"
import googleplayImg from "../../assets/images/googleplayImg.svg"
import appstore from "../../assets/images/appstore.svg"
import parkimg0 from "../../assets/images/parkimg0.png"
import parkimg1 from "../../assets/images/parkimg1.png"
import parkimg2 from "../../assets/images/parkimg2.png"
import parkimg3 from "../../assets/images/parkimg3.png"
import parkimg4 from "../../assets/images/parkimg4.png"
import parkimg5 from "../../assets/images/parkimg5.png"
import parkimg6 from "../../assets/images/parkimg6.png"
import geco_Icon1 from "../../assets/images/geco_Icon1.svg"
import geco_Icon2 from "../../assets/images/geco_Icon2.svg"
import geco_Icon3 from "../../assets/images/geco_Icon3.svg"
import geco_Icon4 from "../../assets/images/geco_Icon4.svg"
import teamLeftImg from "../../assets/images/team-left-img.png"
import secborderline from "../../assets/images/secborderline.svg"
import blog1 from "../../assets/images/blog1.png"
import blog2 from "../../assets/images/blog2.png"
import blog3 from "../../assets/images/blog3.png"

import doubleArrowLeft from "../../assets/images/double-arrow-left.svg"
import doubleArrowRight from "../../assets/images/double-arrow-right.svg"


import HtmlParser from "react-html-parser";
import { useSelector } from "react-redux";


const Home = (props) => {
    const [activeTab, setActiveTab] = useState('1');
    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    const [Blogdata, setBlogdata] = useState("")

    useEffect(() => {
        AOS.init();
    }, [])


    let data = useSelector(state => (state.user));

    console.log(data, "homedata");

    let data1, data2, data3, data4, data5, data6, data7, data8, data9, data10, data11, data12;

    if (data.data.status == 200) {
        data = data.data.data;
        console.log(data, "data2");
        data1 = data.find(elem => elem.title == "Metapark");
        data2 = data.find(elem => elem.title == "Why Metapark?");
        data3 = data.find(elem => elem.title == "Koinpark");
        data4 = data.find(elem => elem.title == "Parkchain")
        data5 = data.find(elem => elem.title == "Parkwallet")
        data6 = data.find(elem => elem.title == "KPK Park")
        data7 = data.find(elem => elem.title == "Parkscan")
        data8 = data.find(elem => elem.title == "ParkTools")
        data9 = data.find(elem => elem.title == "Parklands")
        data10 = data.find(elem => elem.title == "Growth Events")
        data11 = data.find(elem => elem.title == "Teams")
        data12 = data.filter(elem => elem.sub_heading == 'Metapark-Blogs')

    }
    console.log(data12)

    const bnrSettings = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    adaptiveHeight: true
                }
            }
        ]
    };

    const joinSettings = {
        dots: false,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 1600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
        ]
    };

    const joinSlider = React.useRef(null);

    return (
        <Fragment>
            <Header />

            <div class="maincontent" id="homesec1">
                <div class="pageContent p-0">
                    <div class="homepage pt-3 pt-xl-5">

                        <div class="container">
                            <div class="row">
                                <div class="col-12">

                                    <Slider {...bnrSettings} className="bnrSldr">
                                        <div class="row py-xl-5 d-flex align-items-center flex-column-reverse flex-lg-row no-gutters">
                                            <div class="col-lg-6">
                                                <div class="text-left">
                                                    <div class="bannersmallTit">The future of Web3 <br /> Metapark</div>
                                                    <div class="row">
                                                        <div class="col-lg-10">
                                                            <div class="banner-text">The Web3-based digital identity credential can be used for any website, gaming platform, eCommerce site and other online platforms </div>
                                                        </div>
                                                    </div>
                                                    <div class="mt-4 mt-xl-5">
                                                        <a href="https://koinpark.com/" target="_blank" class="btn btn-white btn-h52 pl-4" type="button">Explore
                                                            <span class="pl-3">
                                                                <svg xmlns="http://www.w3.org/2000/svg" class="arrowmove" width="20" height="20"
                                                                    viewBox="0 0 20 20" fill="none">
                                                                    <g clip-path="url(#clip0_31_173)">
                                                                        <path d="M5.83337 5.83334L10 10L5.83337 14.1667" stroke="#131313"
                                                                            stroke-width="1.25" stroke-linecap="round"
                                                                            stroke-linejoin="round" />
                                                                        <path d="M10.8334 5.83334L15 10L10.8334 14.1667" stroke="#131313"
                                                                            stroke-width="1.25" stroke-linecap="round"
                                                                            stroke-linejoin="round" />
                                                                    </g>
                                                                    <defs>
                                                                        <clipPath id="clip0_31_173">
                                                                            <rect width="20" height="20" fill="white" />
                                                                        </clipPath>
                                                                    </defs>
                                                                </svg>
                                                            </span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 text-center">
                                                <div class="text-center d-inline-block mx-auto bnrSldIg">
                                                    <img src={bannerSlide1} alt="bannerSlide1.svg"
                                                        class="img-fluid animate__animated animate__bounce animate__pulse animate__slow animate__infinite" />
                                                </div>
                                            </div>
                                        </div>


                                        <div class="row py-xl-5  d-flex align-items-center flex-column-reverse flex-lg-row no-gutters">
                                            <div class="col-lg-6">
                                                <div class="text-left">
                                                    <div class="bannersmallTit">
                                                        360* <br />
                                                        Web3 Ecosystem
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-lg-10">
                                                            <div class="banner-text">Web3 Ecosystem   (Show our Metapark Eco System Design) = Global Crypto Exchange, Blockchain,  Wallet, Metaverse and NFT Marketplace.</div>
                                                        </div>
                                                    </div>
                                                    <div class="mt-4 mt-xl-5">
                                                        <a href="https://koinpark.com/" target="_blank" class="btn btn-white btn-h52 pl-4" type="button">Explore
                                                            <span class="pl-3">
                                                                <svg xmlns="http://www.w3.org/2000/svg" class="arrowmove" width="20" height="20"
                                                                    viewBox="0 0 20 20" fill="none">
                                                                    <g clip-path="url(#clip0_31_173)">
                                                                        <path d="M5.83337 5.83334L10 10L5.83337 14.1667" stroke="#131313"
                                                                            stroke-width="1.25" stroke-linecap="round"
                                                                            stroke-linejoin="round" />
                                                                        <path d="M10.8334 5.83334L15 10L10.8334 14.1667" stroke="#131313"
                                                                            stroke-width="1.25" stroke-linecap="round"
                                                                            stroke-linejoin="round" />
                                                                    </g>
                                                                    <defs>
                                                                        <clipPath id="clip0_31_173">
                                                                            <rect width="20" height="20" fill="white" />
                                                                        </clipPath>
                                                                    </defs>
                                                                </svg>
                                                            </span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="text-center bnrSldIg">
                                                    <img src={bannerSlide2} alt="bannerSlide2.svg"
                                                        class="img-fluid" />
                                                </div>
                                            </div>
                                        </div>


                                        <div class="row py-xl-5  d-flex align-items-center flex-column-reverse flex-lg-row no-gutters">
                                            <div class="col-lg-6">
                                                <div class="text-left">
                                                    <div class="bannersmallTit">
                                                        Discover and Explore the
                                                        hottest Projects on Parkchain
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-lg-10">
                                                            <div class="banner-text">Discover and Explore the hottest Projects on Parkchain  ( Show icons of  DeFi, GameFi, SocialFi, Metaverse, dApps, Games, Infrastructure) </div>
                                                        </div>
                                                    </div>
                                                    <div class="mt-4 mt-xl-5">
                                                        <a href="https://parkchain.org/" target="_blank" class="btn btn-white btn-h52 pl-4" type="button">Explore
                                                            <span class="pl-3">
                                                                <svg xmlns="http://www.w3.org/2000/svg" class="arrowmove" width="20" height="20"
                                                                    viewBox="0 0 20 20" fill="none">
                                                                    <g clip-path="url(#clip0_31_173)">
                                                                        <path d="M5.83337 5.83334L10 10L5.83337 14.1667" stroke="#131313"
                                                                            stroke-width="1.25" stroke-linecap="round"
                                                                            stroke-linejoin="round" />
                                                                        <path d="M10.8334 5.83334L15 10L10.8334 14.1667" stroke="#131313"
                                                                            stroke-width="1.25" stroke-linecap="round"
                                                                            stroke-linejoin="round" />
                                                                    </g>
                                                                    <defs>
                                                                        <clipPath id="clip0_31_173">
                                                                            <rect width="20" height="20" fill="white" />
                                                                        </clipPath>
                                                                    </defs>
                                                                </svg>
                                                            </span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="text-center bnrSldIg">
                                                    <img src={bannerSlide3} alt="bannerSlide3.svg"
                                                        class="img-fluid" />
                                                </div>
                                            </div>
                                        </div>


                                        <div class="row py-xl-5  d-flex align-items-center flex-column-reverse flex-lg-row no-gutters">
                                            <div class="col-lg-6">
                                                <div class="text-left">
                                                    <div class="bannersmallTit mb-2">
                                                        Globally Trade more than
                                                        300 Cryptos using Ultra fast
                                                        Trade Mechanisms
                                                    </div>
                                                    <div class="banner-subtit">Koinpark Global Trusted Crypto Exchange</div>
                                                    <div class="row">
                                                        <div class="col-lg-10">
                                                            <div class="banner-text"> Globally Trade more than 300 Cryptos using Ultra fast Trade Mechanisms  - Koinpark Global Trusted Crypto Exchange.  </div>
                                                        </div>
                                                    </div>
                                                    <div class="mt-4 mt-xl-5">
                                                        <a href="https://koinpark.com/" target="_blank" class="btn btn-white btn-h52 pl-4" type="button">Explore
                                                            <span class="pl-3">
                                                                <svg xmlns="http://www.w3.org/2000/svg" class="arrowmove" width="20" height="20"
                                                                    viewBox="0 0 20 20" fill="none">
                                                                    <g clip-path="url(#clip0_31_173)">
                                                                        <path d="M5.83337 5.83334L10 10L5.83337 14.1667" stroke="#131313"
                                                                            stroke-width="1.25" stroke-linecap="round"
                                                                            stroke-linejoin="round" />
                                                                        <path d="M10.8334 5.83334L15 10L10.8334 14.1667" stroke="#131313"
                                                                            stroke-width="1.25" stroke-linecap="round"
                                                                            stroke-linejoin="round" />
                                                                    </g>
                                                                    <defs>
                                                                        <clipPath id="clip0_31_173">
                                                                            <rect width="20" height="20" fill="white" />
                                                                        </clipPath>
                                                                    </defs>
                                                                </svg>
                                                            </span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="text-center bnrSldIg">
                                                    <img src={bannerSlide4} alt="bannerSlide4.svg"
                                                        class="img-fluid" />
                                                </div>
                                            </div>
                                        </div>


                                        <div class="row py-xl-5  d-flex align-items-center flex-column-reverse flex-lg-row no-gutters">
                                            <div class="col-lg-6">
                                                <div class="text-left">
                                                    <div class="bannersmallTit mb-2">
                                                        Most Trusted Crypto Wallet
                                                        to Store/Receive/Send your
                                                        Owned Wallet&nbsp;-&nbsp;Parkwallet
                                                    </div>
                                                    <div class="banner-subtit">Join 1 million+ peoples using Park Wallet</div>
                                                    <div class="row">
                                                        <div class="col-lg-10">
                                                            <div class="banner-text">Most Trusted Crypto Wallet to Store/Recieve/Send your Owned Walled  - Parkwallet Join 1 million+ peoples using Park Wallet. </div>
                                                        </div>
                                                    </div>
                                                    <div class="mt-4 mt-xl-5">
                                                        <a href="https://parkwallet.net/" target="_blank" class="btn btn-white btn-h52 pl-4" type="button">Explore
                                                            <span class="pl-3">
                                                                <svg xmlns="http://www.w3.org/2000/svg" class="arrowmove" width="20" height="20"
                                                                    viewBox="0 0 20 20" fill="none">
                                                                    <g clip-path="url(#clip0_31_173)">
                                                                        <path d="M5.83337 5.83334L10 10L5.83337 14.1667" stroke="#131313"
                                                                            stroke-width="1.25" stroke-linecap="round"
                                                                            stroke-linejoin="round" />
                                                                        <path d="M10.8334 5.83334L15 10L10.8334 14.1667" stroke="#131313"
                                                                            stroke-width="1.25" stroke-linecap="round"
                                                                            stroke-linejoin="round" />
                                                                    </g>
                                                                    <defs>
                                                                        <clipPath id="clip0_31_173">
                                                                            <rect width="20" height="20" fill="white" />
                                                                        </clipPath>
                                                                    </defs>
                                                                </svg>
                                                            </span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="text-center bnrSldIg">
                                                    <img src={bannerSlide5} alt="bannerSlide5.svg"
                                                        class="img-fluid" />
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row py-xl-5  d-flex align-items-center flex-column-reverse flex-lg-row no-gutters">
                                            <div class="col-lg-6">
                                                <div class="text-left">
                                                    <div class="bannersmallTit">
                                                        Community powered Political
                                                        Metaverse - Virtual world
                                                        built by you where the only
                                                        limit is your imagination
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-lg-10">
                                                            <div class="banner-text">Community powered Political Metaverse - Virtual world built by YOU where the only limit is your imagination.</div>
                                                        </div>
                                                    </div>
                                                    <div class="mt-4 mt-xl-5">
                                                        <a href="https://parklands.io/" target="_blank" class="btn btn-white btn-h52 pl-4" type="button">Explore
                                                            <span class="pl-3">
                                                                <svg xmlns="http://www.w3.org/2000/svg" class="arrowmove" width="20" height="20"
                                                                    viewBox="0 0 20 20" fill="none">
                                                                    <g clip-path="url(#clip0_31_173)">
                                                                        <path d="M5.83337 5.83334L10 10L5.83337 14.1667" stroke="#131313"
                                                                            stroke-width="1.25" stroke-linecap="round"
                                                                            stroke-linejoin="round" />
                                                                        <path d="M10.8334 5.83334L15 10L10.8334 14.1667" stroke="#131313"
                                                                            stroke-width="1.25" stroke-linecap="round"
                                                                            stroke-linejoin="round" />
                                                                    </g>
                                                                    <defs>
                                                                        <clipPath id="clip0_31_173">
                                                                            <rect width="20" height="20" fill="white" />
                                                                        </clipPath>
                                                                    </defs>
                                                                </svg>
                                                            </span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="text-center bnrSldIg">
                                                    <img src={bannerSlide6} alt="bannerSlide6.svg"
                                                        class="img-fluid" />
                                                </div>
                                            </div>
                                        </div>
                                    </Slider>


                                </div>
                            </div>


                            <div class="text-center scrollbtnsec">
                                <button class="scrollbtn">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <g clip-path="url(#clip0_31_186)">
                                            <path d="M10 2V16" stroke="#1228B2" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M13.3333 12.5L10 15.8333" stroke="#1228B2" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M6.66663 12.5L9.99996 15.8333" stroke="#1228B2" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_31_186">
                                                <rect width="20" height="20" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <section class="secpad-top" >
                    <div class="container">
                        <div class="row align-items-center">
                            <div class="col-lg-5">
                                <div class="sectit">
                                    <div class="metapark-txt">{data1 ? HtmlParser((data1.title).replace(/(<([^>]+)>)/gi, "")) : <></>}</div>
                                    <div class="sec-mainName mb-4">{data1 ? HtmlParser((data1.title1).replace(/(<([^>]+)>)/gi, "")) : <></>}</div>
                                </div>

                                <div class="secpara my-4">{data1 ? HtmlParser((data1.content_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</div>
                            </div>
                            <div class="col-lg-7">
                                <div class="text-lg-right text-center">
                                    <img src={data1 ? (data1.image) : <></>} class="img-fluid" alt="whatismetapark-img" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div class="secpad-top" id="whyMetapark">
                    <section class="secpad darkbg">
                        <div class="container">
                            <div class="row align-items-center">
                                <div class="col-lg-4">
                                    <div class="sectit ml-lg-4">
                                        <div class="metapark-txt text-white">{data1 ? HtmlParser((data1.title).replace(/(<([^>]+)>)/gi, "")) : <></>}</div>
                                        <div class="sec-mainName text-white mb-4">{data2 ? HtmlParser((data2.title).replace(/(<([^>]+)>)/gi, "")) : <></>}</div>
                                    </div>
                                    <div class="secpara light-white my-4 ml-lg-4">
                                        {data2 ? HtmlParser((data2.content_description).replace(/(<([^>]+)>)/gi, "")) : <></>}
                                    </div>
                                </div>
                                <div class="col-lg-8">
                                    <div>
                                        <img src={data2 ? (data2.image) : <></>} alt="whymetapark" class="img-fluid" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

                <section class="secpad-top">
                    <div class="container">
                        <div class="row align-items-center secpad-y-40">
                            <div class="col-lg-6">
                                <div data-aos="fade-right" data-aos-duration="1500" data-aos-anchor-placement="top-center">
                                    <div class="sectit">
                                        <div class="metapark-txt">{data1 ? HtmlParser((data1.title).replace(/(<([^>]+)>)/gi, "")) : <></>}</div>
                                        <div class="sec-mainName mb-4">{data3 ? HtmlParser((data3.title).replace(/(<([^>]+)>)/gi, "")) : <></>}</div>
                                    </div>
                                    <div class="secpara my-4">
                                        <p>{data3 ? HtmlParser((data3.content_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                        <p>{data3 ? HtmlParser((data3.privacy_content).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                        <div class="mt-4">
                                            <a href={data3 ? (data3.link) : <></>} target="_blank" class="btn btn-black btn-h52 pl-4 mr-2 mb-2" role="button">Explore
                                                <span class="pl-3">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" class="arrowmove"
                                                        viewBox="0 0 20 20" fill="none">
                                                        <g clipPath="url(#clip0_31_173)">
                                                            <path d="M5.83337 5.83334L10 10L5.83337 14.1667" stroke="#fff"
                                                                stroke-width="1.25" stroke-linecap="round"
                                                                stroke-linejoin="round" />
                                                            <path d="M10.8334 5.83334L15 10L10.8334 14.1667" stroke="#fff"
                                                                stroke-width="1.25" stroke-linecap="round"
                                                                stroke-linejoin="round" />
                                                        </g>
                                                        <defs>
                                                            <clipPath id="clip0_31_173">
                                                                <rect width="20" height="20" fill="white" />
                                                            </clipPath>
                                                        </defs>
                                                    </svg>
                                                </span>
                                            </a>
                                            <a href="https://play.google.com/store/search?q=koinpark&c=apps&hl=en&gl=US" target="_blank" class="d-inline-block mr-2 mb-2 align-top">
                                                <img src={googleplayImg} alt="googleplay" />
                                            </a>
                                            <a href="https://apps.apple.com/in/app/koinpark/id6448510693" target="_blank" class="d-inline-block mb-2 align-top">
                                                <img src={appstore} alt="appstore" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-5 mt-4 mt-lg-0">
                                <div class="rightleft" data-aos="fade-up" data-aos-duration="1500" data-aos-anchor-placement="top-center">
                                    <img src={data3 ? (data3.image) : <></>} class="img-fluid" alt="parkimg0" />
                                </div>
                            </div>
                        </div>

                        <div class="row align-items-center secpad-y-40">
                            <div class="col-lg-5 mb-4 mb-lg-0 order-lg-1 order-2">
                                <div class="leftimg" data-aos="fade-up" data-aos-duration="1500" data-aos-anchor-placement="top-center">
                                    <img src={data4 ? (data4.image) : <></>} class="img-fluid" alt="parkimg1" />
                                </div>
                            </div>
                            <div class="col-lg-5 offset-lg-1 order-lg-2 order-1">
                                <div data-aos="fade-left" data-aos-duration="1500" data-aos-anchor-placement="top-center">
                                    <div class="sectit">
                                        <div class="metapark-txt">{data1 ? HtmlParser((data1.title).replace(/(<([^>]+)>)/gi, "")) : <></>}</div>
                                        <div class="sec-mainName mb-4">{data4 ? HtmlParser((data4.title).replace(/(<([^>]+)>)/gi, "")) : <></>}</div>
                                    </div>
                                    <div class="secpara my-4">
                                        <p>{data4 ? HtmlParser((data4.content_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                        <div class="mt-4">
                                            <a href={data4 ? (data4.link) : <></>} target="_blank" class="btn btn-black btn-h52 pl-4 mr-2" type="button">Explore
                                                <span class="pl-3">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" class="arrowmove"
                                                        viewBox="0 0 20 20" fill="none">
                                                        <g clip-path="url(#clip0_31_173)">
                                                            <path d="M5.83337 5.83334L10 10L5.83337 14.1667" stroke="#fff"
                                                                stroke-width="1.25" stroke-linecap="round"
                                                                stroke-linejoin="round" />
                                                            <path d="M10.8334 5.83334L15 10L10.8334 14.1667" stroke="#fff"
                                                                stroke-width="1.25" stroke-linecap="round"
                                                                stroke-linejoin="round" />
                                                        </g>
                                                        <defs>
                                                            <clipPath id="clip0_31_173">
                                                                <rect width="20" height="20" fill="white" />
                                                            </clipPath>
                                                        </defs>
                                                    </svg>
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div class="row align-items-center secpad-y-40">
                            <div class="col-lg-6">
                                <div data-aos="fade-right" data-aos-duration="1500" data-aos-anchor-placement="top-center">
                                    <div class="sectit">
                                        <div class="metapark-txt">{data1 ? HtmlParser((data1.title).replace(/(<([^>]+)>)/gi, "")) : <></>}</div>
                                        <div class="sec-mainName mb-4">{data5 ? HtmlParser((data5.title).replace(/(<([^>]+)>)/gi, "")) : <></>}</div>
                                    </div>
                                    <div class="secpara my-4">
                                        <p>{data5 ? HtmlParser((data5.content_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                        <p>{data5 ? HtmlParser((data5.privacy_content).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                        <div class="mt-4">
                                            <a href={data5 ? (data5.link) : <></>} target="_blank" class="btn btn-black btn-h52 pl-4 mr-2 mb-2" type="button">Explore
                                                <span class="pl-3">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" class="arrowmove"
                                                        viewBox="0 0 20 20" fill="none">
                                                        <g clip-path="url(#clip0_31_173)">
                                                            <path d="M5.83337 5.83334L10 10L5.83337 14.1667" stroke="#fff"
                                                                stroke-width="1.25" stroke-linecap="round"
                                                                stroke-linejoin="round" />
                                                            <path d="M10.8334 5.83334L15 10L10.8334 14.1667" stroke="#fff"
                                                                stroke-width="1.25" stroke-linecap="round"
                                                                stroke-linejoin="round" />
                                                        </g>
                                                        <defs>
                                                            <clipPath id="clip0_31_173">
                                                                <rect width="20" height="20" fill="white" />
                                                            </clipPath>
                                                        </defs>
                                                    </svg>
                                                </span>
                                            </a>
                                            <a href="https://play.google.com/store/apps/details?id=com.application.Parkwallet&hl=en&gl=US" target="_blank" class="d-inline-block mr-2 mb-2 align-top">
                                                <img src={googleplayImg} alt="googleplay" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-5 mt-4 mt-lg-0">
                                <div class="rightleft" data-aos="fade-up" data-aos-duration="1500" data-aos-anchor-placement="top-center">
                                    <img src={data5 ? (data5.image) : <></>} class="img-fluid" alt="parkimg2" />
                                </div>
                            </div>

                        </div>
                        <div class="row align-items-center secpad-y-40">
                            <div class="col-lg-5 mb-4 mb-lg-0 order-lg-1 order-2">
                                <div class="leftimg" data-aos="fade-up" data-aos-duration="1500" data-aos-anchor-placement="top-center">
                                    <img src={data6 ? (data6.image) : <></>} class="img-fluid" alt="parkimg3" />
                                </div>
                            </div>
                            <div class="col-lg-5 offset-lg-1 order-lg-2 order-1">
                                <div data-aos="fade-left" data-aos-duration="1500" data-aos-anchor-placement="top-center">
                                    <div class="sectit">
                                        <div class="metapark-txt">{data1 ? HtmlParser((data1.title).replace(/(<([^>]+)>)/gi, "")) : <></>}</div>
                                        <div class="sec-mainName mb-4">
                                            {data6 ? HtmlParser((data6.title).replace(/(<([^>]+)>)/gi, "")) : <></>}                                        </div>
                                    </div>
                                    <div class="fs-15 fw-500 mb-2 text-color13">{data6 ? HtmlParser((data6.subtitle).replace(/(<([^>]+)>)/gi, "")) : <></>}</div>
                                    <div class="secpara mb-4">
                                        {data6 ? HtmlParser((data6.content_description).replace(/(<([^>]+)>)/gi, "")) : <></>}
                                    </div>
                                    <div class="fs-15 fw-500 mb-2 text-color13">{data6 ? HtmlParser((data6.subtitle1).replace(/(<([^>]+)>)/gi, "")) : <></>}</div>
                                    <div class="secpara mb-4">
                                        <p>{data6 ? HtmlParser((data6.privacy_content).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                        <div class="mt-4">
                                            <a href={data6 ? (data6.link) : <></>} target="_blank" class="btn btn-black btn-h52 pl-4 mr-2" type="button">Explore
                                                <span class="pl-3">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" class="arrowmove"
                                                        viewBox="0 0 20 20" fill="none">
                                                        <g clip-path="url(#clip0_31_173)">
                                                            <path d="M5.83337 5.83334L10 10L5.83337 14.1667" stroke="#fff"
                                                                stroke-width="1.25" stroke-linecap="round"
                                                                stroke-linejoin="round" />
                                                            <path d="M10.8334 5.83334L15 10L10.8334 14.1667" stroke="#fff"
                                                                stroke-width="1.25" stroke-linecap="round"
                                                                stroke-linejoin="round" />
                                                        </g>
                                                        <defs>
                                                            <clipPath id="clip0_31_173">
                                                                <rect width="20" height="20" fill="white" />
                                                            </clipPath>
                                                        </defs>
                                                    </svg>
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row align-items-center secpad-y-40">
                            <div class="col-lg-5">
                                <div data-aos="fade-right" data-aos-duration="1500" data-aos-anchor-placement="top-center">
                                    <div class="sectit">
                                        <div class="metapark-txt">{data1 ? HtmlParser((data1.title).replace(/(<([^>]+)>)/gi, "")) : <></>}</div>
                                        <div class="sec-mainName mb-4">{data7 ? HtmlParser((data7.title).replace(/(<([^>]+)>)/gi, "")) : <></>}</div>
                                    </div>

                                    <div class="secpara mb-4">
                                        <p>{data7 ? HtmlParser((data7.privacy_content).replace(/(<([^>]+)>)/gi, "")) : <></>} </p>
                                        <p>{data7 ? HtmlParser((data7.meta_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                        <div class="mt-4">
                                            <a href={data7 ? (data7.link) : <></>} target="_blank" class="btn btn-black btn-h52 pl-4 mr-2" type="button">Explore
                                                <span class="pl-3">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" class="arrowmove"
                                                        viewBox="0 0 20 20" fill="none">
                                                        <g clip-path="url(#clip0_31_173)">
                                                            <path d="M5.83337 5.83334L10 10L5.83337 14.1667" stroke="#fff"
                                                                stroke-width="1.25" stroke-linecap="round"
                                                                stroke-linejoin="round" />
                                                            <path d="M10.8334 5.83334L15 10L10.8334 14.1667" stroke="#fff"
                                                                stroke-width="1.25" stroke-linecap="round"
                                                                stroke-linejoin="round" />
                                                        </g>
                                                        <defs>
                                                            <clipPath id="clip0_31_173">
                                                                <rect width="20" height="20" fill="white" />
                                                            </clipPath>
                                                        </defs>
                                                    </svg>
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-5  offset-lg-1 mt-4 mt-lg-0">
                                <div class="rightleft" data-aos="fade-up" data-aos-duration="1500" data-aos-anchor-placement="top-center">
                                    <img src={data7 ? (data7.image) : <></>} class="img-fluid" alt="parkimg5" />
                                </div>
                            </div>
                        </div>
                        <div class="row align-items-center secpad-y-40">
                            <div class="col-lg-5 mb-4 mb-lg-0 order-lg-1 order-2">
                                <div class="leftimg" data-aos="fade-up" data-aos-duration="1500" data-aos-anchor-placement="top-center">
                                    <img src={data8 ? (data8.image) : <></>} class="img-fluid" alt="parkimg6" />
                                </div>
                            </div>
                            <div class="col-lg-5 offset-lg-1 order-lg-2 order-1">
                                <div data-aos="fade-left" data-aos-duration="1500" data-aos-anchor-placement="top-center">
                                    <div class="sectit">
                                        <div class="metapark-txt">{data1 ? HtmlParser((data1.title).replace(/(<([^>]+)>)/gi, "")) : <></>}</div>
                                        <div class="sec-mainName mb-4">
                                            {data8 ? HtmlParser((data8.title).replace(/(<([^>]+)>)/gi, "")) : <></>}                                        </div>
                                    </div>
                                    <div class="fs-15 fw-500 mb-2 text-color13">{data8 ? HtmlParser((data8.subtitle).replace(/(<([^>]+)>)/gi, "")) : <></>}</div>
                                    <div class="secpara mb-4">
                                        {data8 ? HtmlParser((data8.content_description).replace(/(<([^>]+)>)/gi, "")) : <></>}                                    </div>
                                    <div class="fs-15 fw-500 mb-2 text-color13">{data8 ? HtmlParser((data8.subtitle1).replace(/(<([^>]+)>)/gi, "")) : <></>}</div>
                                    <div class="secpara mb-4">
                                        <p>{data8 ? HtmlParser((data8.privacy_content).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                        <div class="mt-4">
                                            <a href={data8 ? (data8.link) : <></>} target="_blank" class="btn btn-black btn-h52 pl-4 mr-2" type="button">Explore
                                                <span class="pl-3">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" class="arrowmove"
                                                        viewBox="0 0 20 20" fill="none">
                                                        <g clip-path="url(#clip0_31_173)">
                                                            <path d="M5.83337 5.83334L10 10L5.83337 14.1667" stroke="#fff"
                                                                stroke-width="1.25" stroke-linecap="round"
                                                                stroke-linejoin="round" />
                                                            <path d="M10.8334 5.83334L15 10L10.8334 14.1667" stroke="#fff"
                                                                stroke-width="1.25" stroke-linecap="round"
                                                                stroke-linejoin="round" />
                                                        </g>
                                                        <defs>
                                                            <clipPath id="clip0_31_173">
                                                                <rect width="20" height="20" fill="white" />
                                                            </clipPath>
                                                        </defs>
                                                    </svg>
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row align-items-center secpad-y-40">
                            <div class="col-lg-5">
                                <div data-aos="fade-right" data-aos-duration="1500" data-aos-anchor-placement="top-center">
                                    <div class="sectit">
                                        <div class="metapark-txt">{data1 ? HtmlParser((data1.title).replace(/(<([^>]+)>)/gi, "")) : <></>}</div>
                                        <div class="sec-mainName mb-4">{data9 ? HtmlParser((data9.title).replace(/(<([^>]+)>)/gi, "")) : <></>}</div>
                                    </div>

                                    <div class="secpara mb-4">
                                        <p>{data9 ? HtmlParser((data9.content_description).replace(/(<([^>]+)>)/gi, "")) : <></>}</p>
                                        <div class="mt-4">
                                            <a href={data9 ? (data9.link) : <></>} target="_blank" class="btn btn-black btn-h52 pl-4 mr-2" type="button">Explore
                                                <span class="pl-3">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" class="arrowmove"
                                                        viewBox="0 0 20 20" fill="none">
                                                        <g clip-path="url(#clip0_31_173)">
                                                            <path d="M5.83337 5.83334L10 10L5.83337 14.1667" stroke="#fff"
                                                                stroke-width="1.25" stroke-linecap="round"
                                                                stroke-linejoin="round" />
                                                            <path d="M10.8334 5.83334L15 10L10.8334 14.1667" stroke="#fff"
                                                                stroke-width="1.25" stroke-linecap="round"
                                                                stroke-linejoin="round" />
                                                        </g>
                                                        <defs>
                                                            <clipPath id="clip0_31_173">
                                                                <rect width="20" height="20" fill="white" />
                                                            </clipPath>
                                                        </defs>
                                                    </svg>
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="col-lg-5 offset-lg-1 mt-4 mt-lg-0">
                                <div class="rightleft" data-aos="fade-up" data-aos-duration="1500" data-aos-anchor-placement="top-center">
                                    <img src={data9 ? (data9.image) : <></>} class="img-fluid" alt="parkimg4" />
                                </div>
                            </div>

                        </div>
                    </div>
                </section>

                <div class="secpad-top" id="ecosystem">
                    <section class="secpad graybg">
                        <div class="container-fluid">
                            <div class="row align-items-center mb-lg-4">
                                <div class="col-lg-3 offset-lg-2 pl-lg-5">
                                    <div class="sectit">
                                        <div class="metapark-txt">{data1 ? HtmlParser((data1.title).replace(/(<([^>]+)>)/gi, "")) : <></>}</div>
                                        <div class="sec-mainName mb-4">{data9 ? HtmlParser((data9.subtitle).replace(/(<([^>]+)>)/gi, "")) : <></>}</div>
                                    </div>

                                    <div class="secpara my-4">{data9 ? HtmlParser((data9.privacy_content).replace(/(<([^>]+)>)/gi, "")) : <></>}</div>
                                </div>
                                <div class="col-lg-6 offset-lg-1">
                                    <div class="row">
                                        <div class="col-12">

                                            <Slider {...joinSettings} ref={joinSlider}>
                                                <div className="hmecardslideCnt">
                                                    <div class="card hmecardslide">
                                                        <div class="card-body">
                                                            <div class="iconbgbx-sm">
                                                                <img src={geco_Icon1} alt="geco_Icon" />
                                                            </div>
                                                            <div class="my-3 fs-16 fw-500 text-color13">
                                                                {data10 ? HtmlParser((data10.title).replace(/(<([^>]+)>)/gi, "")) : <></>}
                                                            </div>
                                                            <div class="cardcnt">
                                                                {data10 ? HtmlParser((data10.content_description).replace(/(<([^>]+)>)/gi, "")) : <></>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="hmecardslideCnt">
                                                    <div class="card hmecardslide">
                                                        <div class="card-body">
                                                            <div class="iconbgbx-sm">
                                                                <img src={geco_Icon2} alt="geco_Icon" />
                                                            </div>
                                                            <div class="my-3 fs-16 fw-500 text-color13">
                                                                {data10 ? HtmlParser((data10.title1).replace(/(<([^>]+)>)/gi, "")) : <></>}
                                                            </div>
                                                            <div class="cardcnt">
                                                                {data10 ? HtmlParser((data10.privacy_content).replace(/(<([^>]+)>)/gi, "")) : <></>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="hmecardslideCnt">
                                                    <div class="card hmecardslide">
                                                        <div class="card-body">
                                                            <div class="iconbgbx-sm">
                                                                <img src={geco_Icon3} alt="geco_Icon" />
                                                            </div>
                                                            <div class="my-3 fs-16 fw-500 text-color13">
                                                                {data10 ? HtmlParser((data10.subtitle).replace(/(<([^>]+)>)/gi, "")) : <></>}                                                            </div>
                                                            <div class="cardcnt">
                                                                {data10 ? HtmlParser((data10.meta_description).replace(/(<([^>]+)>)/gi, "")) : <></>}                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="hmecardslideCnt">
                                                    <div class="card hmecardslide">
                                                        <div class="card-body">
                                                            <div class="iconbgbx-sm">
                                                                <img src={geco_Icon4} alt="geco_Icon" />
                                                            </div>
                                                            <div class="my-3 fs-16 fw-500 text-color13">
                                                                {data10 ? HtmlParser((data10.subtitle1).replace(/(<([^>]+)>)/gi, "")) : <></>}                                                            </div>
                                                            <div class="cardcnt">
                                                                {data10 ? HtmlParser((data10.privacy).replace(/(<([^>]+)>)/gi, "")) : <></>}                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Slider>

                                            <div className="joinSldrBtns">
                                                <button className="btn" onClick={() => joinSlider?.current?.slickPrev()}>
                                                    <img src={doubleArrowLeft} alt="doubleArrowLeft" />
                                                </button>
                                                <button className="btn" onClick={() => joinSlider?.current?.slickNext()}>
                                                    <img src={doubleArrowRight} alt="doubleArrowRight" />
                                                </button>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        0                  </section>
                </div>
                <section class="bg-primary teamSec" id="team">
                    <div class="row no-gutters align-items-center">
                        <div class="col-lg-6">
                            <div class="" data-aos="zoom-in-left" data-aos-duration="1500" data-aos-anchor-placement="top-center">
                                <img src={data11 ? (data11.image) : <></>} alt="team-left-img" class="w-100 img-fluid" />
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="px-5 bgline">
                                <div class="row">
                                    <div class="col-xl-6 col-lg-10 col-md-12">
                                        <div class="sectit ml-md-5">
                                            <div class="metapark-txt text-white">{data1 ? HtmlParser((data1.title).replace(/(<([^>]+)>)/gi, "")) : <></>}</div>
                                            <div class="sec-mainName text-white mb-4">{data11 ? HtmlParser((data11.title).replace(/(<([^>]+)>)/gi, "")) : <></>}</div>
                                        </div>
                                        <div class="secpara light-white my-4 ml-md-5">
                                            {data11 ? HtmlParser((data11.content_description).replace(/(<([^>]+)>)/gi, "")) : <></>}                                        </div>

                                        <div class="mt-5 ml-md-5">
                                            <img src={secborderline} alt="secborderline" class="img-fluid" />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>


                <section class="secpad graybg secpadbottom" id="blogsec">
                    <div class="container">
                        <div class="row align-items-center">
                            <div class="col-12 text-center">
                                <div class="fs-26 fw-400 mb-2">Metapark-Blogs</div>
                                <div class="secpara mb-5">Build your Crypto Knowledge With the Best Stories, Insights and Information</div>
                            </div>
                        </div>
                        <div class="row">
                            {data12 && data12.length > 0 ?
                                data12.map((item, index) => (

                                    <div class="col-lg-4 col-md-6">
                                        <div class="blogcard">
                                            <div class="blogimg">
                                                <img src={item.image} class="img-fluid w-100" alt="blog1" />
                                            </div>
                                            <div class="blogcnt">
                                                <div class="blogtit">{HtmlParser(item.title)}</div>
                                                <div class="blogpara">{(item.content_description).replace(/(<([^>]+)>)/gi, "").slice(0, 250) + '...'}</div>
                                                <div class="blog-footer">Crypto Guide  |  07 Jun 2023</div>
                                            </div>
                                        </div>
                                    </div>

                                )) : <></>
                            }
                            {/* <div class="col-lg-4 col-md-6">
                                <div class="blogcard">
                                    <div class="blogimg">
                                        <img src={blog2} class="img-fluid w-100" alt="blog2" />
                                    </div>
                                    <div class="blogcnt">
                                        <div class="blogtit">Koinpark Introduces the World's First &
                                            Greatest Political Metaverse</div>
                                        <div class="blogpara">In a world increasingly influenced by technology, the boundaries between the physical and virtual realms are blurring. </div>
                                        <div class="blog-footer">Crypto Guide  |  07 Jun 2023</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6">
                                <div class="blogcard">
                                    <div class="blogimg">
                                        <img src={blog3} class="img-fluid w-100" alt="blog3" />
                                    </div>
                                    <div class="blogcnt">
                                        <div class="blogtit">Koinpark Introduces the World's First &
                                            Greatest Political Metaverse</div>
                                        <div class="blogpara">In a world increasingly influenced by technology, the boundaries between the physical and virtual realms are blurring. </div>
                                        <div class="blog-footer">Crypto Guide  |  07 Jun 2023</div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                        <div class="text-center mt-4">
                            <a href="https://www.koinpark.com/blog?type=blog" target="_blank" class="btn btn-black btn-h52 pl-4" type="button">View More
                                <span class="pl-3">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" class="arrowmove"
                                        viewBox="0 0 20 20" fill="none">
                                        <g clip-path="url(#clip0_31_173)">
                                            <path d="M5.83337 5.83334L10 10L5.83337 14.1667" stroke="#fff"
                                                stroke-widths="1.25" stroke-linecap="round"
                                                stroke-linejoin="round" />
                                            <path d="M10.8334 5.83334L15 10L10.8334 14.1667" stroke="#fff"
                                                stroke-width="1.25" stroke-linecap="round"
                                                stroke-linejoin="round" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_31_173">
                                                <rect width="20" height="20" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </span>
                            </a>
                        </div>
                    </div>
                </section>


            </div >

            <Footer />
        </Fragment >
    );

}

export default Home;